import XLSX from "xlsx";


export function export_table_to_excel(theTable, defaultTitle) {
  let book = XLSX.utils.book_new();
  let sheet = XLSX.utils.table_to_sheet(theTable);
  let ws_name = "SheetJS";
  let title = defaultTitle || "数据列表";
  XLSX.utils.book_append_sheet(book, sheet, ws_name);
  XLSX.writeFile(book,  title + ".xlsx");
}


export function export_json_to_excel(headers, jsonData, defaultTitle) {

  let book = XLSX.utils.book_new();
  var data = jsonData || [];
  data.unshift(headers);

  let sheet = XLSX.utils.aoa_to_sheet(data);
  let ws_name = "SheetJS";
  XLSX.utils.book_append_sheet(book, sheet, ws_name);
  let title = defaultTitle || "数据列表";
  XLSX.writeFile(book,  title + ".xlsx");
}


/**
 * 
 * @param {文件} file 
 * @returns 
 */
export function readToJson(file){

  let readPromise = new Promise((resolve, reject) => {

      let reader = new FileReader();
      reader.onload = () => {
        
        try{
          let bufferData = reader.result;
          let workbook = XLSX.read(bufferData, {
            type: "buffer"
          });
        
          let firstName = workbook.SheetNames[0];
          let firstSheet = workbook.Sheets[firstName];
        
          let res = XLSX.utils.sheet_to_json(firstSheet);
        
          resolve(res);
        }catch(err){
          reject(err);
        }
       
    
      };
      
      reader.readAsArrayBuffer(file);
  });

  
  let timeoutPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
        reject(new Error("读取超时"));
    }, 6000);
  });

  return Promise.race([readPromise, timeoutPromise]);
  

}